<template>
  <div class="class-container">
    <div >
      <div class="searchRow">
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">复健类型：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.fjxm"
                      placeholder="请选择...">
                <Option :value="v.id"
                        v-for="v in healthDevice"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">复健日期：</span>
            <div class="sub-content">
              <DatePicker
                type="daterange"
                placeholder="请选择..."
                style="width: 100%"
                @on-change="handleDateChange"></DatePicker>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">录入人：</span>
            <div class="sub-content">
              <Input v-model="searchData.fjdjr" placeholder="请搜索员工名称"></Input>
            </div>
          </Col>
          <Col span="4" offset="1">
            <Button type="primary" @click="handleSearch">搜索</Button>
          </Col>
        </Row>
      </div>
      <div class="content-box">
        <div class="content-body" style="margin-top:0px">
          <Table
            :columns="reConstruColumns"
            :data="classList"
            ref="table"
            @on-selection-change="handleDataSelect"></Table>
            <div>
              <span class="records">共 {{totalClasses}} 条记录</span>
            </div>
            <div class="page-nations-wrapper">
              <div class="page-nations">
                <Page
                  :total="totalClasses"
                  :current="searchData.page"
                  :page-size="searchData.size"
                  @on-change="changeDataPage"></Page>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="查看详情"
      class-name="vertical-center-modal"
      width="1000"
      footer-hide
      :loading="modalLoading"
      >
      <Form :model="formData" :label-width="110" disabled>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="复健项目">
              <Select v-model="formData.fjxm_id">
                <Option
                  :value="type.id"
                  v-for="type in healthDevice"
                  :key="type.id">{{ type.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="11" >
            <Form-item label="地址">
              <Input v-model="formData.fjdz" placeholder="请输入活动地址"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="复健时间">
              <DatePicker
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择复健日期"
                style="width: 50%;"
                :value="activityDate.date"
                :options="options"
                @on-change="handleModalDateChange"></DatePicker>
                <TimePicker
                type="time"
                format="HH:mm"
                placeholder="请选择复健时间"
                style="width: 50%;"
                :value="activityDate.time"
                hide-disabled-options
                :disabled-hours="[0,1,2,3,4,5,6,22,23]"
                @on-change="handleModalDateChange"></TimePicker>
                <!-- <div style="margin-top:5px;"><span style="color:red;">* </span>请选择活动日期和时间</div> -->
            </Form-item>
          </Col>
          <Col span="11">
            <Form-item label="活动时长">
              <TimePicker
                :value="formData.fjsc"
                format="H小时m分"
                placeholder="请选择"
                style="width: 100%;"
                @on-change="handleModalDurationChange"></TimePicker>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="登记人">
              <span style="line-height: 34px;">{{ formData.fjdjrname  }}</span>
            </Form-item>
          </Col>
        </Row>
        <Form-item label="参与顾客">
          <Select v-model="formData.fjcygk" filterable multiple>
            <Option v-for="item in memberList" :value="item.id" :key="item.id">{{ `${item.branch_name} ${item.true_name}` }}</Option>
          </Select>
          <Input v-model="formData.fjfhygk" placeholder="请输入参与的非会员顾客"></Input>
        </Form-item>
        <Form-item label="复健图片">
          <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="item.name">
            <template v-if="item.status === 'finished'">
              <img :src="item.url">
              <div class="demo-upload-list-cover">
                <Icon type="ios-eye" @click.native="handlePreviewImg(uploadList, index)"></Icon>
              </div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>
          <Upload action="/api/fujian/photo/upload"
              name="photo"
              ref="upload"
              multiple
              accept=".png,.jpg,.jpeg"
              :default-file-list="defaultImgList"
              :show-upload-list="false"
              :max-size="1024 * 30"
              :format="['png','jpg','jpeg']"
              :on-format-error="handleFormatError"
              :on-success="handleUploadSuccess"
              :on-exceeded-size="handleMaxSize"
              :before-upload="handleBeforeUpload"
              style="display: inline-block;width:60px;vertical-align: middle;">
              <div class="upload-btn" v-if="actionType !== 'view'">
                <Icon type="md-add" size="25"></Icon>
                <p style="font-size:12px;line-height: 1.5;">添加图片</p>
              </div>
          </Upload>
        </Form-item>
        <Form-item label="复健简介">
          <Input v-model="formData.fjjj" placeholder="请输入复健简介" type="textarea" :rows="4"></Input>
        </Form-item>
      </Form>
    </Modal>
    <Modal
      v-model="showPreviewModal"
      title="图片预览"
      class-name="vertical-center-modal"
      width="800">
      <div class="preview-wrapper" v-if="previewImages.length > 0">
        <Icon type="ios-arrow-back" size="26" @click="previewPrev" style="margin-right: 10px;"></Icon>
        <div class="img-wrapper">
          <img :src="previewImages[previewIndex].url" />
        </div>
        <Icon type="ios-arrow-forward" size="26" @click="previewNext" style="margin-left: 10px;"></Icon>  
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';
import HomeService from '@/services/homeService';
import dayjs from 'dayjs';

export default {
	data() {
		return {
			storeList: [],
			proList: [],
			cityList: [],
			memberList: [],
			staffList: [],
			searchData: {
				page: 1,
				size: 15,
				pid: '',
				cid: '',
				sid: '',
				fjcygk: '', // 参加顾客
				fjdjr: '', // 登记人
				fjxm: '', // 复健id
				start_time: '',
				end_time: '',
			},
			healthDevice: [],
			totalClasses: 0,
			selectedDataItem: [],
			classList: [],
			reConstruColumns: [
				{
					title: '复健时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.fjsj);
					},
				},
				{
					title: '录入时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.create_time);
					},
				},
				{ title: '复健项目', key: 'fjxm', align: 'center' },
				{ title: '复健人数', key: 'number', align: 'center' },
				{ title: '复健时长', key: 'fjsc', align: 'center' },
				{
					title: '所属门店',
					key: 'ssmd',
					align: 'center',
					render: (h, params) => {
						return params.row.ssmd.split(',').map((item) => {
							return h(
								'div',
								{
									style: { width: '100%' },
								},
								item,
							);
						});
					},
				},
				{ title: '复健简介', key: 'fjjj', align: 'center' },
				{ title: '录入人', key: 'fjdjrname', align: 'center' },
				{ title: '地址', key: 'fjdz', align: 'center' },
				{
					title: '活动图片',
					key: 'staff',
					align: 'center',
					render: (h, params) => {
						const images = params.row.fjtp;
						return images.length > 0
							? h(
									'div',
									{
										style: { height: '80px', width: '60px', padding: '5px 0', margin: 'auto' },
									},
									[
										h('img', {
											style: { height: '100%', width: '100%' },
											attrs: { src: images[0].url },
											on: {
												click: () => this.handlePreviewImg(images, 0),
											},
										}),
									],
								)
							: '';
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const actions = [
							h('a', {
								class: 'icon_eye',
								style: {
									marginRight: 0,
								},
								on: {
									click: () => {
										this.handleShowModal('view');
										HomeService.recontrucDetail({ id: params.row.id }).then((data) => {
											this.formData.fjxm_id = data.fjxm_id;
											this.formData.fjdz = data.fjdz;
											//   this.formData.zjr2 = data.zjr2
											this.formData.fjsc = data.fjsc;
											this.formData.fjdjrname = data.fjdjrname;
											this.formData.fjdjr_id = data.fjdjr_id;
											this.formData.fjfhygk = data.fjfhygk;
											this.formData.fjcygk = data.fjcygk.split(',').map((item) => +item);
											this.defaultImgList = data.tp;
											setTimeout(() => {
												this.uploadList = this.$refs.upload.fileList;
											}, 500);
											this.formData.fjjj = data.fjjj;
											const fjsj = data.fjsj.split(' ');
											this.activityDate.date = fjsj[0];
											this.activityDate.time = fjsj[1];
										});
									},
								},
							}),
						];
						return h('div', actions);
					},
				},
			],
			showModal: false,
			modalLoading: true,
			formData: {
				fjxm_id: '', // 复健id
				fjdz: '', // 复健地址
				fjsj: '', // 复健时间
				fjsc: '', //附件时长
				djr: '', // 登记人
				fjdjr_id: '', // 登记人id
				lrr_id: '', // 录入人id
				fjcygk: [], //参与顾客
				fjjj: '', // 复健简介
				fjtp: '', //复健图片
				fjfhygk: '', // 复健非会员顾客(手动输入)
			},
			activityDate: {
				date: '',
				time: '',
			},
			uploadList: [],
			defaultImgList: [],
			actionType: 'add',
			previewIndex: 0,
			previewImages: [],
			showPreviewModal: false,
		};
	},
	computed: {
		options() {
			return {
				disabledDate(date) {
					const today = dayjs().valueOf() - 1000;
					return date && (date.valueOf() > today || date.valueOf() < today - 86400000 * 10);
				},
			};
		},
	},
	created() {
		const list = JSON.parse(localStorage.getItem('userInfo'));
		this.formData.fjdz = list.user_info.branch_name;
		this.formData.fjdjrname = list.user_info.name;
		this.power = list.user_info.power;
		this.formData.djr = list.user_info.name;
		this.formData.fjdjr_id = list.user_info.id;
		this.formData.lrr_id = list.user_info.id;
		qualityService.getQualitySelect().then((data) => {
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		memberService.getHealthDevice().then((data) => {
			this.healthDevice = data;
		});
		this.handleSearch();
		this.handleShopChange();
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		handleShopChange() {
			memberService
				.kechengUser({
					page: 1,
					size: 10000,
					is_staff: 0,
				})
				.then((data) => {
					this.memberList = data.list;
				});
		},
		changeDataPage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.id);
		},
		handleSearch() {
			this.searchData.member_id = this.$route.params.member_id;
			HomeService.queryReConstru(this.searchData).then((data) => {
				this.classList = data.list;
				this.totalClasses = data.row_size;
			});
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.searchData.start_time = val[0];
				this.searchData.end_time = val[1];
			} else {
				this.searchData.start_time = null;
				this.searchData.end_time = null;
			}
		},
		handleUploadSuccess(response, file) {
			if (response.c === 0) {
				file.url = response.data.photo;
				file.id = response.data.id;
			}
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 30 M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg、jpeg 格式的图片。',
			);
		},
		handleBeforeUpload() {
			if (this.uploadList.length >= 9) {
				this.$Message.warning('最多只能上传 9 张照片');
				return false;
			}
		},
		handleModalDurationChange(value) {
			this.formData.fjsc = value;
		},
		handleModalDateChange(value) {
			if (value.indexOf(':') > -1) {
				this.activityDate.time = value;
			} else {
				this.activityDate.date = value;
			}
		},
		handleShowModal(type) {
			this.actionType = type;
			this.showModal = true;
		},
		handlePreviewImg(images, index) {
			this.showPreviewModal = true;
			this.previewImages = images;
			this.previewIndex = index;
		},
		previewPrev() {
			if (this.previewIndex > 0) this.previewIndex--;
		},
		previewNext() {
			if (this.previewIndex < this.previewImages.length - 1) this.previewIndex++;
		},
	},
};
</script>

<style lang="less" scoped>
.searchRow{
    padding: 20px;
    background-color: #fff;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 80px;
}
.content-title {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin-right: 4px;
  vertical-align: middle;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.upload-btn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
}
.preview-wrapper {
  display: flex;
  align-items: center;
  .img-wrapper {
    flex: 1;
    padding-bottom: 75%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: contain;
    }
  }
}
</style>